@import 'src/utils/utils';

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background-color: #efae76;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  font-size: 0;
  clip: rect(0 0 0 0);
}

.static {
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
}

body {
  @include Text-14-reg;
  display: flex;
  min-width: 320px;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: $Main-4;
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  text-underline-position: under;
}

textarea {
  resize: vertical;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.text-crop-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}


.text-crop-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media(min-width: $md) {
    -webkit-line-clamp: 2;
  }
}

.text-crop-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@keyframes bounce {

  0% {
    transform: translateX(1000px);
  }

  70% {
    transform: translateX(-30px);
  }

  90% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes show-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}